@import '~theme/variables';

.container {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 $space-m 0;

  @include breakpoint(sm) {
    min-height: 36rem;
    justify-content: center;
    padding: $space-xl 0;
  }

  @include breakpoint(md) {
    min-height: 40rem;
  }

  @include breakpoint(lg) {
    min-height: 44rem;
  }

  &.isCollage {
    padding: 0;
    @include breakpoint(sm) {
      height: auto;
      margin-top: $space-l;
    }

    @include breakpoint(sm, max) {
      .row {
        display: flex;
        flex-direction: column-reverse;
      }
      .collageCol {
        height: 16rem;
        margin-bottom: $space-l;
      }
    }
  }

  &.alternative {
    background-color: $color-neutral-06;
    padding: 2.4rem 0;

    @include breakpoint(sm) {
      height: auto;
      justify-content: center;
    }

    @include breakpoint(md) {
      height: auto;
      padding: 4rem 0;
    }
  }

  &.is-neutral-06 {
    background-color: $color-neutral-06;
  }

  &.stickToBottom {
    margin-bottom: 0;
  }
}

.image-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: 192rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(16, 15, 13, 0.6) 5.68%,
      rgba(16, 15, 13, 0.3) 39.34%,
      rgba(16, 15, 13, 0) 62.11%
    );
    @include breakpoint(sm) {
      background: linear-gradient(
        267deg,
        rgba(0, 0, 0, 0) 28.51%,
        rgba(16, 15, 13, 0.3) 61.58%,
        rgba(16, 15, 13, 0.6) 89.56%
      );
    }
  }

  &.centralized {
    &::after {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 3.86%,
        rgba(0, 0, 0, 0.3) 94.2%
      );
    }
  }

  &.transparent {
    &::after {
      background: transparent;
    }
  }
}

.image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.body {
  position: relative;

  padding-left: $space-xs;
  padding-right: $space-xs;

  @include breakpoint(md) {
    padding-left: $space-l;
    padding-right: $space-l;
  }

  @include breakpoint(md) {
    padding-left: $space-xxl;
    padding-right: $space-xxl;

    &.isLeftAligned {
      padding-left: $space-xxl;
      padding-right: $space-xxxl;
    }
  }

  &.bodyPaddingTop {
    @include breakpoint(sm, max) {
      padding-top: 17.6rem;
    }
  }
}

.centralized {
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    min-width: 30rem;
  }

  h1,
  h2,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }

  ul {
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.title {
  margin-bottom: $space-s;
}

.subtitle {
  margin-bottom: 0.8rem;
}

.link {
  margin-top: 2.4rem;
}

.cta {
  margin-top: 2.4rem;
}

.largeSizeOnTablet {
  @include breakpoint(md) {
    font-size: $large-font-size;
  }
}

.prevLink {
  color: $color-neutral-06;
  flex-direction: row-reverse;
}
